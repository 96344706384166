<template>
  <div class="zheline">
    <div class="price-body">
      <ve-line :data="chartData" :settings="chartSettings" height="400px" :legend-visible=false></ve-line>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Zheline",
    data() {
      this.chartSettings = {
        min: [70],
        labelMap: {
          outbidprice:'成交价'
        }
      }
      return {
        chartData: {
          columns: ['bidname','outbidprice'],
          rows: [

          ]
        },
      }
    },
    activated(){
      this.getHistoryList();
    },
    methods:{
      //历史趋势
      getHistoryList() {
        console.log(this.productshortname,'历史1'),
                this.$fetch('tpproductinfo/historySas', {
                  'productname': this.$route.query.productshortname,
                }, true).then((data) => {
                  if (data && data.code === 0) {
                    this.chartData.rows=data.dealList;
                    console.log(data.dealList,'历史')
                  } else {
                    this.chartData = []
                    this.$message({
                      message: data.msg,
                      type: 'error',
                      showClose: 'true'
                    })
                  }
                })
      }
    }
  }
</script>

<style scoped>
  @import "../assets/css/index.css";
  @import "../assets/css/page/zheline.scss";
</style>
